import Card from "./Card"

export default function CardSection({title, articles}) {
  return (
    <div className="card-section">
        <h2>{title}</h2>
        <div className="card-wrapper">
            <Card title={articles[0].title} extract={articles[0].extract} id={articles[0]._id} imgData={articles[0].imgData} alt={articles[0].alt}/>
            <Card title={articles[1].title} extract={articles[1].extract} id={articles[1]._id} imgData={articles[1].imgData} alt={articles[1].alt}/>
            <Card title={articles[2].title} extract={articles[2].extract} id={articles[2]._id} imgData={articles[2].imgData} alt={articles[2].alt}/>
        </div>
    </div>
  )
}
