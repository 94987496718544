export default function Hero({titleText, sectionText}) {
  return (
    <section className="hero">
        <h1 className="section-title">
            {titleText}
        </h1>
        <h3 className="section-text">
            {sectionText}
        </h3>
    </section>
  )
}
