import Hero from "./Hero";
import CardSection from "./CardSection";
import heroData from "./hero.json"
import { Link, useLoaderData } from "react-router-dom"
import axios from 'axios'
import dummyArticleList from "./dummyArticleList"
import ROOT_URL from "./BackendUrl"

export default function Home() {

    const articles = useLoaderData()

    return (
        <>
            <Hero 
                titleText={heroData.mainTitle}
                sectionText={heroData.introText}
            />
            <CardSection title="Latest articles" articles={articles}/>
            <Link to="/articles" target="_self">See all articles</Link>
        </>
    )
}


export const latestArticlesLoader = async (n) => {
    try {
        const url = `${ROOT_URL}/articles/latest/${n}`
        const response = await axios.get(url)
        const articles = response.data
        console.log(articles)
        return articles
    } catch (error) {
        console.error('Error fetching articles:', error)
        const articles = dummyArticleList
        console.log(articles)
        return articles
    }
}