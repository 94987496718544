// Contact details
import { Link } from "react-router-dom"

export function Contact() {

  return (
    <div className="contact-section">
        <h2>Contact us</h2>
        <div className="contact-wrapper">
            <h4>Join our private discussion group on Facebook</h4>
            <p><Link to="https://www.facebook.com/groups/190133381585705" target="_blank">Peterborough & Fenland Polyamory</Link></p>
        </div>
        <Link to="/" target="_self">Go Home...</Link>
    </div>
  )
}
