const APP_ENV = process.env.REACT_APP_ENV
let ROOT_URL

try {
    if (APP_ENV === "prod") {
        const BACKEND_IP = process.env.REACT_APP_BACKEND_PROD_IP
        console.log(BACKEND_IP);
        ROOT_URL = 'https://'+BACKEND_IP
        console.log('Running in production mode');
    }
    else if (APP_ENV === "dev") {
        const BACKEND_IP = process.env.REACT_APP_BACKEND_DEV_IP
        console.log(BACKEND_IP);
        const BACKEND_PORT = process.env.REACT_APP_BACKEND_DEV_PORT
        console.log(BACKEND_PORT);
        ROOT_URL = 'http://'+BACKEND_IP+':'+BACKEND_PORT
        console.log('Running in development mode');
    }
    else {
        throw new Error('The environment variables are not properly defined. This is most likely caused by a typo in the REACT_APP_ENV variable value, or ommission of the REACT_APP_BACKEND_PROD_IP, REACT_APP_BACKEND_DEV_IP or REACT_APP_BACKEND_DEV_PORT variables.');
    }
  } catch (error) {
    console.error(`Caught an error: ${error.message}`);
    process.exit();
  }
  
console.log(ROOT_URL);
export default ROOT_URL
