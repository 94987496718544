// Shows a list of all articles

import { useLoaderData, Link } from "react-router-dom"
import axios from 'axios'
import dummyArticleList from "./dummyArticleList"
import ROOT_URL from "./BackendUrl"

export function Articles() {

    const articles = useLoaderData()

  return (
    <div className="articles-section">
        <h2>List of articles</h2>
        <div className="articles-wrapper">{articles.map(item=>(
            <div className="article-container" key={item.id}>
                <h4>{item.title}</h4>
                <p>{item.extract}<span><Link to={"/article/" + item._id} target="_self">More...</Link></span></p>
            </div>
        ))}</div>
        <Link to="/" target="_self">Go Home...</Link>
    </div>
  )
}

export const ArticlesLoader = async () => {
    try {
        const response = await axios.get(ROOT_URL + '/articles')
        const articles = response.data
        return articles
    } catch (error) {
        console.error('Error fetching articles:', error)
        const articles = dummyArticleList.json()
        return articles
    }
}