const dummyArticleList = [
    {
        alt:"Loading...",
        author:"Loading...",
        extract:"Loading...",
        id:101,
        imgData:"Loading...",
        imgFileName:"Loading...",
        publishDate:"Loading...",
        text:"Loading...",
        title:"Loading...",
    },
    {
        alt:"Loading...",
        author:"Loading...",
        extract:"Loading...",
        id:102,
        imgData:"Loading...",
        imgFileName:"Loading...",
        publishDate:"Loading...",
        text:"Loading...",
        title:"Loading...",
    },
    {
        alt:"Loading...",
        author:"Loading...",
        extract:"Loading...",
        id:103,
        imgData:"Loading...",
        imgFileName:"Loading...",
        publishDate:"Loading...",
        text:"Loading...",
        title:"Loading...",
    }
]

export default dummyArticleList