// Displays a single article

import { Link, useLoaderData, Outlet } from "react-router-dom"
import parser from "html-react-parser"
import dummyArticleList from "./dummyArticleList"
import ROOT_URL from "./BackendUrl"

export function Article() {

    const article = useLoaderData()
    const text = parser(article.text)
    // console.log(text)
    // console.log(article)

    return (
        <>
        {!article && <p>Article not found</p>}
        <div className="article">
            <img src={`data:image/jpeg;base64,${article.imgData}`} alt={article.alt} />
            <h3>{article.title}</h3>
            <p className="date">Published on <time dateTime={article.publishDate}>{article.publishDate}</time></p>
            <p className="author">By {article.author}</p>
            <p className="body-text">{text}</p>
            <Link to="/" target="_self">Go Home...</Link>
        </div>
        </>
    )
}

export function ArticleTemplate() {
    return (
        <div>
            <Outlet/>
        </div>
    )
}

export const articleLoader = async ({params}) => {
    // const BACKEND_IP = process.env.REACT_APP_BACKEND_IP
    // const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT
    // const ROOT_URL = 'http://'+BACKEND_IP+':'+BACKEND_PORT
    try {
        const {id} = params
        const res = await fetch(ROOT_URL + '/article/' + id)
        return res.json()
    } catch {
        const article = dummyArticleList[0].json()
        console.log(article)
        return article
    }
}
