
import { createBrowserRouter, createRoutesFromElements, Route, NavLink, Outlet, RouterProvider, useRouteError } from "react-router-dom"
import Home, {latestArticlesLoader} from "./Home"
import {Article, ArticleTemplate, articleLoader} from "./Article";
import {Articles, ArticlesLoader} from "./Articles";
import {Contact} from "./Contact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

// To run json-server: json-server -p 4000 -w ./data/articles.json
// -p = port number
// -w = watch

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<HomeTemplate/>}>
                <Route index element={<Home/>} loader={() => latestArticlesLoader(3)} errorElement={<ErrorBoundary />}/>
                <Route path="articles" element={<Articles/>} loader={ArticlesLoader} errorElement={<ErrorBoundary />}/>
                <Route path="article" element={<ArticleTemplate/>}>
                    <Route path=":id" element={<Article/>} loader={articleLoader} errorElement={<ErrorBoundary />}/>
                </Route>
                <Route path="contact" element={<Contact/>}/>
            </Route>
        )
    )

    return (
        <RouterProvider router={router}/>
    )
}
    
export default App;
    
const HomeTemplate = () => {
    const handleLinkClick = () => {
        // Toggle the checkbox state when a link is clicked
        const checkbox = document.getElementById('check');
        console.log("Toggling checkbox...")
        checkbox.checked = !checkbox.checked;
    };

    return (
        <>
            <header>
                <nav>
                    <label htmlFor="title">
                        PFP
                    </label>
                    <input type="checkbox" id="check"/>
                    <ul>
                        <li><NavLink to="/" onClick={handleLinkClick} activeclassname="active">Home</NavLink></li>
                        <li><NavLink to="/articles" onClick={handleLinkClick} activeclassname="active">Articles</NavLink></li>
                        <li><NavLink to="/contact" onClick={handleLinkClick} activeclassname="active">Contact</NavLink></li>
                    </ul>
                    <label htmlFor="check" className="check-btn">
                        <FontAwesomeIcon icon={faBars} />
                    </label>
                </nav>
            </header>
            <main>
                <Outlet/>
            </main>
            <footer>
                
            </footer>
        </>
    )
}

// For handling errors
function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <div>Dang!</div>;
}

// Resources
// See this tutorial for router in v6.4+: https://youtu.be/z0vaVoxMoSA?si=5W10GCi4vYGuiIYk
// For url params: https://youtu.be/2x2zp7xnq7s?si=QuTt9pQg4SooiZn0
// Tutorial for hamburger menu: https://youtu.be/oLgtucwjVII?si=0iLrvtjxK4ncLHSL or https://youtu.be/fL8cFqhTHwA?si=CBn3ANp4X1gWmPsB
// Router error handling: https://reactrouter.com/en/main/route/error-element