import { Link } from "react-router-dom"

export default function Card({id, title, extract, imgData, alt}) {
  return (
    <div className="card">
        <img src={`data:image/jpeg;base64,${imgData}`} alt={alt} />
        <h4>{title}</h4>
        <p>{extract}</p>
        <Link to={"/article/" + id} target="_self">More...</Link>
    </div>
  )
}
